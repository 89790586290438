/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin'; // eslint-disable-line import/no-unresolved

import MemberTitle from './MemberTitle';

const MemberShow = ({ permissions, ...props }) => (
    <Show title={<MemberTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="member.form.summary">
                <TextField source="id" />
                <TextField source="artistName" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

MemberShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default MemberShow;
