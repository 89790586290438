/* eslint react/jsx-key: off */
import PeopleIcon from '@material-ui/icons/People';
import memoize from 'lodash/memoize';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';

import {
    BulkDeleteWithConfirmButton,
    Datagrid,
    Filter,
    List,
    SearchInput,
    SimpleList,
    TextField,
    TextInput,
    FunctionField,
} from 'react-admin';

import MemberShowEmbedded from './MemberShowEmbedded';
export const MemberIcon = PeopleIcon;

const MemberFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <SearchInput source="email" alwaysOn />
        <TextInput source="email" />
    </Filter>
);

const MemberBulkActionButtons = props => (
    <BulkDeleteWithConfirmButton {...props} />
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return Promise.resolve('edit');
});

const parseMembership = (record) => {
    if (!record.memberships || !record.memberships.pro) {
        return <span>None</span>
    } else if (record.memberships.pro.status != 'active') {
        return <span>Pro (inactive)</span>
    } else {
        return <span>Pro</span>
    }
}

const MemberList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<MemberFilter permissions={permissions} />}
        sort={{ field: 'created', order: 'DESC' }}
        bulkActionButtons={<MemberBulkActionButtons />}
    >
        {useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
            <SimpleList
                primaryText={record => record.email}
                secondaryText={record => record.artistName}
            />
        ) : (
            <Datagrid
                rowClick={rowClick(permissions)}
                expand={<MemberShowEmbedded />}
                optimized
            >
                <TextField source="email" />
                <TextField source="artistName" />
                <FunctionField label="Membership" render={parseMembership} />
                <TextField source="credits" />
            </Datagrid>
        )}
    </List>
);

export default MemberList;
