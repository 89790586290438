import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate, useQueryWithStore } from 'react-admin';
import Utils from '../utils'

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const NewVersions = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const thisWeek = useMemo(() => {
        return Utils.getMonday(new Date());
    }, []);

    const { loaded, data: visitors } = useQueryWithStore({
        type: 'getList',
        resource: 'versions',
        payload: {
            filter: {
                created: {
                    gte: thisWeek.toISOString(),
                }
            },
            sort: { field: 'created', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    if (!loaded) return null;

    const nb = visitors ? visitors.reduce(nb => ++nb, 0) : 0;
    const getArtistNames = (record) => {
      return record.artists.length ? record.artists.map(artist => artist.name).join(', ') : 'unknown';
    }

    return (
        <div className={classes.main}>
            <CardIcon Icon={SubscriptionsIcon} bgColor="#536DFE" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {translate('dashboard.new_versions')}
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {nb}
                </Typography>
                <Divider />
                <List>
                    {visitors
                        ? visitors.map(record => (
                              <ListItem
                                  button
                                  to={`/versions/${record.id}/show`}
                                  component={Link}
                                  key={record.id}
                              >
                                  <ListItemText
                                      primary={`${record.song.name} by ${getArtistNames(record)}`}
                                  />
                              </ListItem>
                          ))
                        : null}
                </List>
            </Card>
        </div>
    );
};

export default NewVersions;
