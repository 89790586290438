/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin'; // eslint-disable-line import/no-unresolved

import ArtistTitle from './ArtistTitle';

const ArtistShow = ({ permissions, ...props }) => (
    <Show title={<ArtistTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="member.form.summary">
                <TextField source="id" />
                <TextField source="name" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

ArtistShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default ArtistShow;
