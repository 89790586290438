/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const ProMembershipField = ({ record = {} }) => {
    if (!record.memberships || !record.memberships.pro) {
        return <span>None.</span>
    } else {
        if (record.memberships.pro.status != 'active') {
            return <span>Pro (inactive).</span>
        } else {
            return <span>Sub ID: {record.memberships.pro.subscriptionId}</span>
        }
    }
};
ProMembershipField.defaultProps = {
    addLabel: true,
    label: 'Pro Membership'
};

const MemberShowEmbedded = ({ permissions, ...props }) => (
    /* Passing " " as title disables the custom title */
    <Show title=" " {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="credits" />
            <ProMembershipField />
        </SimpleShowLayout>
    </Show>
);

MemberShowEmbedded.propTypes = {
    record: PropTypes.object,
    basePath: PropTypes.string,
    resource: PropTypes.string,
    id: PropTypes.string,
};

export default MemberShowEmbedded;
