/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const SongEditEmbedded = ({ permissions, ...props }) => (
    /* Passing " " as title disables the custom title */
    <Show title=" " {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
        </SimpleShowLayout>
    </Show>
);

SongEditEmbedded.propTypes = {
    record: PropTypes.object,
    basePath: PropTypes.string,
    resource: PropTypes.string,
    id: PropTypes.string,
};

export default SongEditEmbedded;
