import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import {
    TopToolbar,
    Edit,
    ShowButton,
    FormTab,
    SimpleForm,
    TextInput,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import VersionTitle from './VersionTitle';

const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);

const VersionEdit = ({ permissions, ...props }) => (
    <Edit title={<VersionTitle />} actions={<EditActions />} {...props}>
        <SimpleForm defaultValue={{ average_note: 0 }}>
            <TextInput source="version" />
        </SimpleForm>
    </Edit>
);

export default VersionEdit;
