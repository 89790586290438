import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Chip from '@material-ui/core/Chip';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import React, { Children, Fragment, cloneElement } from 'react';
import lodashGet from 'lodash/get';
import memoize from 'lodash/memoize';
import jsonExport from 'jsonexport/dist';
import {
    ArrayField,
    SingleFieldList,
    BooleanField,
    BulkDeleteButton,
    Datagrid,
    DateField,
    downloadCSV,
    Filter,
    List,
    ReferenceField,
    SearchInput,
    SimpleList,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import VersionEditEmbedded from './VersionEditEmbedded';
import ResetViewsButton from './ResetViewsButton';
export const VersionIcon = SubscriptionsIcon;

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const VersionFilter = props => (
    <Filter {...props}>
        <SearchInput source="song.name" alwaysOn />
        <TextInput
            source="song.name"
        />
        <QuickFilter
            label="resources.versions.fields.unpublished"
            source="published"
            defaultValue="false"
        />
    </Filter>
);

const exporter = versions => {
    const data = versions.map(version => ({
        ...version,
        backlinks: lodashGet(version, 'backlinks', []).map(
            backlink => backlink.url
        ),
    }));
    jsonExport(data, (err, csv) => downloadCSV(csv, 'versions'));
};

const useStyles = makeStyles(theme => ({
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    publishedAt: { fontStyle: 'italic' },
}));

const VersionListBulkActions = props => (
    <Fragment>
        <ResetViewsButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return Promise.resolve('edit');
});

const VersionList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            bulkActionButtons={<VersionListBulkActions />}
            filters={<VersionFilter />}
            sort={{ field: 'created', order: 'DESC' }}
            exporter={exporter}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.song.name}
                    secondaryText={record => record.artists.map(artist => artist.name).join(', ')}
                />
            ) : (
                <Datagrid rowClick={rowClick(permissions)} expand={<VersionEditEmbedded />} optimized>
                    <TextField source="song.name" cellClassName={classes.title} />
                    <ArrayField source="artists">
                        <SingleFieldList>
                            <ReferenceField link="show" source="id" reference="artists">
                                <TextField source="name" />
                            </ReferenceField>
                        </SingleFieldList>
                    </ArrayField>
                    <ReferenceField link="show" source="createdBy" reference="members">
                        <TextField source="email" />
                    </ReferenceField>
                    <DateField
                        source="lastUpdated"
                        cellClassName={classes.publishedAt}
                    />
                    <BooleanField source="published" />
                </Datagrid>
            )}
        </List>
    );
};

export default VersionList;
