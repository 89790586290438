/* eslint react/jsx-key: off */
import React from 'react';
import { Admin, Resource } from 'react-admin'; // eslint-disable-line import/no-unresolved
import { render } from 'react-dom';
import { Route } from 'react-router-dom';

import comments from './comments';
import CustomRouteLayout from './customRouteLayout';
import CustomRouteNoLayout from './customRouteNoLayout';
import dataProvider from './dataProvider';
import i18nProvider from './i18nProvider';
import Layout from './Layout';
import { Dashboard } from './dashboard'

// Our resources
import songs from './songs';
import versions from './versions';
import members from './members';
import artists from './artists';

// Kept for reference
import posts from './posts';
import users from './users';
import tags from './tags';

/**
 * Authentication
 */

import firebase from 'firebase'
import FirebaseAuthProvider from './firebaseAuthProvider'
import firebaseConfig from './firebaseConfig'

// Ensure firebase is initialized first
firebase.initializeApp(firebaseConfig)

/**
 * Render app
 */

render(
    <Admin
        authProvider={FirebaseAuthProvider()}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        title="Flowra Admin"
        layout={Layout}
        dashboard={Dashboard}
        customRoutes={[
            <Route
                exact
                path="/custom"
                component={props => <CustomRouteNoLayout {...props} />}
                noLayout
            />,
            <Route
                exact
                path="/custom2"
                component={props => <CustomRouteLayout {...props} />}
            />,
        ]}
    >
        {permissions => [
            <Resource name="songs" {...songs} />,
            <Resource name="versions" {...versions} />,
            <Resource name="artists" {...artists} />,
            <Resource name="members" {...members} />,
            false ? <Resource name="posts" {...posts} /> : null,
            false ? <Resource name="comments" {...comments} /> : null,
            false ? <Resource name="users" {...users} /> : null,
            false ? <Resource name="tags" {...tags} /> : null,
        ]}
    </Admin>,
    document.getElementById('root')
);
