import React from 'react';
import { useTranslate } from 'react-admin';

export default ({ record }) => {
    const translate = useTranslate();
    return (
        <span>
            {record
                ? translate('song.edit.name', { name: record.name })
                : ''}
        </span>
    );
};
