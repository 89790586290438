import React from 'react';
import { useTranslate } from 'react-admin';

export default ({ record }) => {
    const translate = useTranslate();
    const artistsNames = record.artists.map(artist => artist.name).join(', ');
    return (
        <span>
            {record
                ? translate('version.edit.name', { name: record.song.name, artist: artistsNames })
                : ''}
        </span>
    );
};
