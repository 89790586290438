/* eslint react/jsx-key: off */
import PeopleIcon from '@material-ui/icons/People';
import memoize from 'lodash/memoize';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';

import {
    BulkDeleteWithConfirmButton,
    Datagrid,
    Filter,
    List,
    SearchInput,
    SimpleList,
    TextField,
    TextInput,
    ReferenceField,
    DateField
} from 'react-admin';

import ArtistShowEmbedded from './ArtistShowEmbedded';
export const ArtistIcon = PeopleIcon;

const ArtistFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <SearchInput source="email" alwaysOn />
        <TextInput source="email" />
    </Filter>
);

const ArtistBulkActionButtons = props => (
    <BulkDeleteWithConfirmButton {...props} />
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return Promise.resolve('edit');
});

const ArtistList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<ArtistFilter permissions={permissions} />}
        sort={{ field: 'updated', order: 'DESC' }}
        bulkActionButtons={<ArtistBulkActionButtons />}
    >
        {useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
            <SimpleList
                primaryText={record => record.email}
                secondaryText={record => record.name}
            />
        ) : (
            <Datagrid
                rowClick={rowClick(permissions)}
                expand={<ArtistShowEmbedded />}
                optimized
            >
                <TextField source="name" />
                <DateField source="updated" />
                <ReferenceField source="createdBy" reference="members">
                    <TextField source="email" />
                </ReferenceField>
            </Datagrid>
        )}
    </List>
);

export default ArtistList;
