import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate, useQueryWithStore } from 'react-admin';
import Utils from '../utils'

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const NewMembers = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const thisWeek = useMemo(() => {
        return Utils.getMonday(new Date());
    }, []);

    const { loaded, data: visitors } = useQueryWithStore({
        type: 'getList',
        resource: 'members',
        payload: {
            filter: {
                created: {
                    gte: thisWeek.toISOString(),
                }
            },
            sort: { field: 'created', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        },
    });

    if (!loaded) return null;

    const nb = visitors ? visitors.reduce(nb => ++nb, 0) : 0;
    return (
        <div className={classes.main}>
            <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {translate('dashboard.new_members')}
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {nb}
                </Typography>
                <Divider />
                <List>
                    {visitors
                        ? visitors.map(record => (
                              <ListItem
                                  button
                                  to={`/members/${record.id}/show`}
                                  component={Link}
                                  key={record.id}
                              >
                                  <ListItemText
                                      primary={record.artistName || record.email}
                                  />
                              </ListItem>
                          ))
                        : null}
                </List>
            </Card>
        </div>
    );
};

export default NewMembers;
