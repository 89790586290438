import englishMessages from 'ra-language-english';

export const messages = {
    simple: {
        action: {
            close: 'Close',
            resetViews: 'Reset views',
        },
        'create-post': 'New post',
    },
    ...englishMessages,
    dashboard: {
        new_members: 'New members this week',
        new_versions: 'New versions this week',
    },
    resources: {
        songs: {
            name: 'Song |||| Songs',
            fields: {
                name: 'Name',
                updated: 'Last updated',
                lfTrackRequired: 'LyricFind',
                lfTrack: {
                    lfid: 'LF ID',
                    writers: 'Writers'
                }
            },
        },
        versions: {
            name: 'Version |||| Versions',
            fields: {
                unpublished: 'Unpublished',
                'song.name': 'Song'
            },
        },
        posts: {
            name: 'Post |||| Posts',
            fields: {
                average_note: 'Average note',
                body: 'Body',
                comments: 'Comments',
                commentable: 'Commentable',
                commentable_short: 'Com.',
                created_at: 'Created at',
                notifications: 'Notifications recipients',
                nb_view: 'Nb views',
                password: 'Password (if protected post)',
                pictures: 'Related Pictures',
                published_at: 'Published at',
                teaser: 'Teaser',
                tags: 'Tags',
                title: 'Title',
                views: 'Views',
                authors: 'Authors',
            },
        },
        comments: {
            name: 'Comment |||| Comments',
            fields: {
                body: 'Body',
                created_at: 'Created at',
                post_id: 'Posts',
                author: {
                    name: 'Author',
                },
            },
        },
        members: {
            name: 'Member |||| Members',
            fields: {
                name: 'Name',
                role: 'Role',
            },
        },
        users: {
            name: 'User |||| Users',
            fields: {
                name: 'Name',
                role: 'Role',
            },
        },
    },
    song: {
        list: {
            search: 'Search',
        },
        form: {
            general: 'General',
            lyricFind: 'LyricFind'
        },
        edit: {
            name: 'Song "%{name}"',
        },
        action: {
            save_and_edit: 'Save and Edit',
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show'
        },
    },
    version: {
        list: {
            search: 'Search',
        },
        form: {
            general: 'General'
        },
        edit: {
            name: 'Version of "%{name}" by "%{artist}"',
        },
        action: {
            save_and_edit: 'Save and Edit',
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show'
        },
    },
    post: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            miscellaneous: 'Miscellaneous',
            comments: 'Comments',
        },
        edit: {
            title: 'Post "%{title}"',
        },
        action: {
            save_and_edit: 'Save and Edit',
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
            save_with_average_note: 'Save with Note',
        },
    },
    comment: {
        list: {
            about: 'About',
        },
    },
    member: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            security: 'Security',
        },
        edit: {
            title: 'Member "%{title}"',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    user: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            security: 'Security',
        },
        edit: {
            title: 'User "%{title}"',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
};

export default messages;
