/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    AutocompleteInput,
    TabbedForm,
    TextInput,
    Toolbar,
    required,
} from 'react-admin';

const MemberEditToolbar = ({
    permissions,
    hasList,
    hasEdit,
    hasShow,
    hasCreate,
    ...props
}) => (
    <Toolbar {...props}>
        <SaveButton
            label="member.action.save_and_show"
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label="member.action.save_and_add"
                redirect={false}
                submitOnEnter={false}
                variant="text"
            />
        )}
    </Toolbar>
);

const MemberCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <TabbedForm
            toolbar={<MemberEditToolbar permissions={permissions} {...props} />}
        >
            <FormTab label="member.form.summary" path="">
                <TextInput
                    source="name"
                    defaultValue="Slim Shady"
                    autoFocus
                    validate={required()}
                />
            </FormTab>
            {permissions === 'admin' && (
                <FormTab label="member.form.security" path="security">
                    <AutocompleteInput
                        source="role"
                        choices={[
                            { id: '', name: 'None' },
                            { id: 'admin', name: 'Admin' },
                            { id: 'Member', name: 'Member' },
                            { id: 'Member_simple', name: 'MemberSimple' },
                        ]}
                    />
                </FormTab>
            )}
        </TabbedForm>
    </Create>
);

export default MemberCreate;
