import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import VersionCreate from './VersionCreate';
import VersionEdit from './VersionEdit';
import VersionList from './VersionList';
import VersionShow from './VersionShow';

export default {
    list: VersionList,
    // NB disabled because we don't offer 'create version' in admin
    // create: VersionCreate,
    edit: VersionEdit,
    show: VersionShow,
    icon: SubscriptionsIcon,
};
