/**
 * Copied from ra-auth-firebase.
 * Removed storing profile data in firebase database. Instead query profile data
 * from Flowra API and store data locally.
 * 
 * Adapted to work with react-admin-loopback: store the JWT token in 'lbtoken'.
 */
import { AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin'
import firebase from 'firebase'
import deepAssign from 'deep-assign'

import { storage } from 'react-admin-loopback';
import fetchJson from './../node_modules/react-admin-loopback/lib/fetch';
import CONFIG from './config';

const baseConfig = {
  noAccessPage: '/login',
  admin: {
    validate: (profile) => {
      // Deny access unless user has 'admin' role
      return profile.userRoles && profile.userRoles.length && profile.userRoles.find((role) => {
        return role.name === 'admin'
      })
    }
  },
  keys: {
    roles: 'roles',
  },
  handleAuthStateChange: async (auth, config) => {
    if (auth) {
      const firebaseToken = await firebase.auth().currentUser.getIdToken()

      // Store the access token where react-admin-loopback will look for it
      // when making requests.
      const ttl = 60 * 60;
      storage.save('lbtoken', {
        id: 'Bearer ' + firebaseToken
      }, ttl);

      //
      // Authorization: only admins may access flowra-admin
      //

      // Get the member profile data (requires token to be set in local storage)
      const response = await fetchJson(CONFIG.apiUrl + '/members/me')

      if (response.status === 200 && config.admin.validate(response.json)) {
        const roles = JSON.stringify(response.json.userRoles)
        localStorage.setItem(config.keys.roles, roles)
        return {
          profile: roles,
          firebaseToken: firebaseToken
        }
      } else {
        firebase.auth().signOut()
        storage.remove('lbtoken');
        return Promise.reject(new Error('Oops! You don\'t seem to be a authorized user'))
      }
    } else {
      storage.remove('lbtoken');
    }
  }
}

export default (config = {}) => {
  config = deepAssign({}, baseConfig, config)

  const firebaseLoaded = () => new Promise(resolve => {
    firebase.auth().onAuthStateChanged(resolve)
  })

  return async (type, params) => {
    if (type === AUTH_LOGOUT) {
      await config.handleAuthStateChange(null, config)
      return firebase.auth().signOut()
    }

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload()
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded()

      if (!firebase.auth().currentUser) {
        return Promise.reject(new Error('Oops! You don\'t seem to be signed in.'))
      }

      const token = storage.load('lbtoken');
      if (token && token.id) {
        return Promise.resolve();
      } else {
        storage.remove('lbtoken');
        return Promise.reject({ redirectTo: config.noAccessPage });
      }
    }

    if (type === AUTH_LOGIN) {
      const { username, password } = params
      let auth = firebase.auth().currentUser

      if (!auth) {
        try {
          auth = await firebase.auth().signInWithEmailAndPassword(username, password)
        } catch (error) {
          return Promise.reject(error)
        }
      }

      return config.handleAuthStateChange(auth, config)
    }

    if (type === AUTH_ERROR) {
      const { status } = params;
      if (status === 401 || status === 403) {
        storage.remove('lbtoken');
        return Promise.reject();
      }
      return Promise.resolve();
    }

    if (type === AUTH_GET_PERMISSIONS) {
      const data = localStorage.getItem(config.keys.roles)
      if (!data) {
        return Promise.reject(new Error('Could not get permissions'));
      }

      const adminRole = JSON.parse(data).find((role) => {
        if (role.name === 'admin') {
          return role;
        };
      });

      return Promise.resolve(adminRole ? 'admin' : '');
    }

    return false
  }
}
