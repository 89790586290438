/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    DeleteWithConfirmButton,
    Edit,
    FormTab,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import MemberTitle from './MemberTitle';

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const MemberEditToolbar = props => {
    const classes = useToolbarStyles();
    return (
        <Toolbar {...props} classes={classes}>
            <SaveButton />
            <DeleteWithConfirmButton />
        </Toolbar>
    );
};

const MemberEdit = ({ permissions, ...props }) => (
    <Edit title={<MemberTitle />} {...props}>
        <SimpleForm
            defaultValue={{ role: 'Member' }}
            toolbar={<MemberEditToolbar />}
        >
            <TextInput
                source="artistName"
                validate={required()}
            />
        </SimpleForm>
    </Edit>
);

MemberEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default MemberEdit;
