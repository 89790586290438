import PeopleIcon from '@material-ui/icons/People';
import MemberCreate from './MemberCreate';
import MemberEdit from './MemberEdit';
import MemberList from './MemberList';
import MemberShow from './MemberShow';

export default {
    list: MemberList,
    // NB disabled because we don't offer 'create member' in admin
    create: MemberCreate,
    edit: MemberEdit,
    show: MemberShow,
    icon: PeopleIcon,
};
