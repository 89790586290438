import { useShowController } from 'ra-core';
import React from 'react';
import PropTypes from 'prop-types';
import {
    BooleanField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import VersionTitle from './VersionTitle';

const VersionShow = ({ permissions, ...props }) => (
    <Show title={<VersionTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="version.form.general">
                <TextField source="id" />
                <TextField source="version" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

VersionShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default VersionShow;
