import { useShowController } from 'ra-core';
import React from 'react';
import PropTypes from 'prop-types';
import {
    BooleanField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import SongTitle from './SongTitle';

const SongShow = ({ permissions, ...props }) => (
    <Show title={<SongTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="song.form.general">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="originalArtist" />
            </Tab>
            <Tab label="song.form.lyricFind">
                <BooleanField source="lfTrackRequired" />
                {props.record && props.record.lfTrack && [
                    <TextField source="lfTrack.lfid" />,
                    <TextField source="lfTrack.writers" />
                ]}
            </Tab>
        </TabbedShowLayout>
    </Show>
);

SongShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default SongShow;
