import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import {
    TopToolbar,
    Edit,
    ShowButton,
    FormTab,
    SimpleForm,
    TextInput,
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import SongTitle from './SongTitle';

const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        {hasShow && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
);

const SongEdit = ({ permissions, ...props }) => (
    <Edit title={<SongTitle />} actions={<EditActions />} {...props}>
        <SimpleForm defaultValue={{ average_note: 0 }}>
            <TextInput source="name" />
            <TextInput source="originalArtist" />
        </SimpleForm>
    </Edit>
);

export default SongEdit;
