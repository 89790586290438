import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SongCreate from './SongCreate';
import SongEdit from './SongEdit';
import SongList from './SongList';
import SongShow from './SongShow';

export default {
    list: SongList,
    // NB disabled because we don't offer 'create song' in admin
    // create: SongCreate,
    edit: SongEdit,
    show: SongShow,
    icon: MusicNoteIcon,
};
