import BookIcon from '@material-ui/icons/Book';
import Chip from '@material-ui/core/Chip';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import React, { Children, Fragment, cloneElement } from 'react';
import lodashGet from 'lodash/get';
import memoize from 'lodash/memoize';
import jsonExport from 'jsonexport/dist';
import {
    BulkDeleteButton,
    Datagrid,
    DateField,
    downloadCSV,
    Filter,
    List,
    ReferenceField,
    SearchInput,
    SimpleList,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import SongShowEmbedded from './SongShowEmbedded';
import ResetViewsButton from './ResetViewsButton';
export const SongIcon = BookIcon;

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const SongFilter = props => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn />
        <TextInput
            source="name"
        />
    </Filter>
);

const exporter = songs => {
    const data = songs.map(song => ({
        ...song,
        backlinks: lodashGet(song, 'backlinks', []).map(
            backlink => backlink.url
        ),
    }));
    jsonExport(data, (err, csv) => downloadCSV(csv, 'songs'));
};

const useStyles = makeStyles(theme => ({
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    publishedAt: { fontStyle: 'italic' },
}));

const SongListBulkActions = props => (
    <Fragment>
        <ResetViewsButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

const useSongListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
});

const SongListActionToolbar = ({ children, ...props }) => {
    const classes = useSongListActionToolbarStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

const rowClick = memoize(permissions => (id, basePath, record) => {
    return Promise.resolve('edit');
});

const SongList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            bulkActionButtons={<SongListBulkActions />}
            filters={<SongFilter />}
            sort={{ field: 'updated', order: 'DESC' }}
            exporter={exporter}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.originalArtist}
                />
            ) : (
                <Datagrid rowClick={rowClick(permissions)} optimized>
                    <TextField source="name" cellClassName={classes.title} />
                    <TextField source="originalArtist" />
                    <ReferenceField source="createdBy" reference="members" link="show">
                        <TextField source="email" />
                    </ReferenceField>
                    <DateField
                        source="updated"
                        cellClassName={classes.publishedAt}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default SongList;
