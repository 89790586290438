/* eslint react/jsx-key: off */
import React from 'react';
import { useTranslate } from 'react-admin';

const MemberTitle = ({ record }) => {
    const translate = useTranslate();
    return (
        <span>
            {record ? translate('member.edit.title', { title: record.artistName }) : ''}
        </span>
    );
};

export default MemberTitle;
