const Utils = {};

// Get Datetimestamp of the current week's Monday at 0h
Utils.getMonday = ( date ) => {
    var day = date.getDay() || 7;  
    if( day !== 1 ) 
        date.setHours(-24 * (day - 1)); 
    return date;
}

export default Utils;