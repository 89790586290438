import FaceIcon from '@material-ui/icons/Face';
import ArtistCreate from './ArtistCreate';
import ArtistEdit from './ArtistEdit';
import ArtistList from './ArtistList';
import ArtistShow from './ArtistShow';

export default {
    list: ArtistList,
    // NB disabled because we don't offer 'create member' in admin
    create: ArtistCreate,
    edit: ArtistEdit,
    show: ArtistShow,
    icon: FaceIcon,
};
